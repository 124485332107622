import htmx from "htmx.org";
import "preline";
import * as hyperscript from "hyperscript.org";
// import _ from "lodash";
// import ApexCharts from "apexcharts";

window.htmx = htmx;
// window._ = _;
// window.ApexCharts = ApexCharts;

hyperscript.browserInit();
window.HSStaticMethods.autoInit();

htmx.onLoad(function () {
  window.HSStaticMethods.autoInit();
});
